import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { GridProjects, Hero, HeroFilter, Page, SectionMedia } from "components";
import { getCategory, getCategoryFilters, getCategoryPath } from "utils";

// TODO:
// - pass referral options to contact form using gatsby link state

const TemplateProjectCategory = ({
  data: { projectCategories, projectTypes, projects },
  location,
}) => {
  const category = getCategory(projects);
  const categoryPath = getCategoryPath(category);
  const filterButtons = getCategoryFilters(projectCategories, "/projects", {
    label: "All Projects",
  });
  const categoryLowerCase = category.toLowerCase();
  const categoryTypes =
    projectTypes &&
    projectTypes.group.map(({ type }) => ` ${type.toLowerCase()}`);

  const seoOptions = {
    title: `${category} Projects`,
    openGraph: {
      url: location.href,
    },
  };

  const pageOptions = {
    header: {
      color: "secondary",
    },
  };

  const headerOptions = {
    bgcolor: "primary.main",
    buttons: filterButtons,
    className: "primary",
    color: "common.white",
    heading: `Professional ${categoryLowerCase} work for commercial and residential of projects`,
  };

  const heroOptions = {
    buttons: [
      {
        label: `About ${categoryLowerCase} services`,
        path: categoryPath,
      },
      {
        label: `Get ${categoryLowerCase} services`,
        path: "/contact",
      },
    ],
    component: "section",
    context: `Need help with your commercial or residential ${categoryLowerCase} project? We have qualified professionals providing expert ${categoryLowerCase} services all over Las Vegas, Nevada.${
      projectTypes &&
      ` We can help you with all types of ${categoryLowerCase} work, like ${categoryTypes}, and more.`
    } You can learn about our ${categoryLowerCase} services or contact us about to talk about your project - just click the button to get started.`,
    heading: `High quality ${categoryLowerCase} services in Las Vegas and parts of Southern Nevada`,
  };

  return (
    <Page seo={seoOptions} options={pageOptions}>
      <HeroFilter {...headerOptions} />
      {projects && (
        <SectionMedia>
          <GridProjects projects={projects} />
        </SectionMedia>
      )}
      <Hero {...heroOptions} />
    </Page>
  );
};

TemplateProjectCategory.propTypes = {
  data: PropTypes.shape({
    projectCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string,
        })
      ),
    }),
    projects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              category: PropTypes.string,
              date: PropTypes.string,
              description: PropTypes.string,
              draft: PropTypes.bool,
              image: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                  fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number,
                    base64: PropTypes.string,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                    sizes: PropTypes.string,
                  }),
                }),
              }),
              title: PropTypes.string,
              type: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const projectsByCategoryQuery = graphql`
  query ProjectsByCategory($category: String!) {
    projectCategories: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/projects/" }
        frontmatter: { draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___category) {
        category: fieldValue
      }
    }
    projectTypes: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/projects/" }
        frontmatter: { category: { eq: $category }, draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___type) {
        type: fieldValue
      }
    }
    projects: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/projects/" }
        frontmatter: { category: { eq: $category }, draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            date(formatString: "MMM. DD, YYYY")
            description
            draft
            image {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 480, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            type
          }
          id
        }
      }
    }
  }
`;

export default TemplateProjectCategory;
